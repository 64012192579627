import { domain } from '../apiConfig/apiConfig'

export const fetchPageById = async (pageId) => {
  const result = await fetch(`${domain}pages/get/${pageId}`)
  return result.json()
}

export const fetchPages = async () => {
  const result = await fetch(`${domain}pages/getall`)
  return result.json()
}

export const editPage = async (editPageData) => {
  const dataToApi = {
    page_text: editPageData.pageText,
  }
  const result = await fetch(`${domain}pages/update/${editPageData.pageId}`, {
    method: 'put',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}
