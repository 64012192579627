import classes from './Login.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { Form } from '../../UI/Form/Form'
import {
  assignSessionIdIfAny,
  checkLoginStatusAsync,
  getSessionId,
  getUserInfoAsync,
  hasLoginRequestBeenMade,
  loginAsync,
  resetLoginRequestMade,
  setLogout,
  getIsLoading,
  getIsLoggedIn,
} from '../../../features/login/loginSlice'
import { Button } from '../../UI/Button/Button'
import { Pumplend } from './Pumplend/Pumplend'
import { WaterCheck } from './WaterCheck/WaterCheck'
import { Route, Routes, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { getUserInfo } from '../../../features/login/loginSlice'
import { Settings } from '../Settings/Settings'
import { ApproveUsers } from './ApproveUsers/ApproveUsers'
import { EditPages } from './EditPages/EditPages'
import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { toastifyConfig } from '../../../features/toastifyConfig/toastifyConfig'
import { ElectricityBoxLend } from './ElectricityBoxLend/ElectricityBoxLend'
import { Link } from 'react-router-dom'
import { Documentsupload } from './Documentsupload/Documentsupload'

export const Login = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(getIsLoggedIn)
  const sessionId = useSelector(getSessionId)
  const loginRequestMade = useSelector(hasLoginRequestBeenMade)
  const [credentialsState, setCredendialsState] = useState({
    username: '',
    password: '',
  })
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    dispatch(assignSessionIdIfAny())
    const timer = setInterval(() => {
      if (sessionId !== null) {
        console.log('Login status checked')
        dispatch(checkLoginStatusAsync(sessionId))
      }
    }, 60000)
    return () => clearInterval(timer)
  }, [])

  useEffect(() => {
    if (loginRequestMade && isLoggedIn) {
      dispatch(getUserInfoAsync(sessionId))
      navigate('Pumplend')
    } else if (loginRequestMade && !isLoggedIn) {
      setErrorMessage('Inloggningsuppgifterna var felaktiga')
    } else if (!loginRequestMade && isLoggedIn) {
      dispatch(getUserInfoAsync(sessionId))
      navigate('Pumplend')
    } else {
      navigate('/Login')
    }

    return () => {
      dispatch(resetLoginRequestMade())
    }
  }, [loginRequestMade, isLoggedIn, sessionId])

  const formDefinition = [
    { type: 'label', labelText: 'Användarnamn' },
    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, username: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Lösenord' },
    {
      type: 'password',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, password: event.target.value }
        })
      },
    },
  ]

  const allDataSet = () =>
    Object.values(credentialsState).filter((x) => x !== '').length ===
    Object.keys(credentialsState).length

  const buttonClickedHandler = (event, username, password) => {
    event.preventDefault()
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }
    dispatch(loginAsync({ username, password }))
    navigate('Pumplend')
  }

  useEffect(() => {
    if (errorMessage.length !== 0) {
      toast.error(errorMessage, toastifyConfig)
    }
    return () => {
      setErrorMessage('')
    }
  }, [errorMessage])

  return (
    <>
      {!isLoggedIn && (
        <>
          <Form
            formDefinition={formDefinition}
            buttonText="Login"
            clicked={(event) => {
              buttonClickedHandler(
                event,
                credentialsState.username,
                credentialsState.password,
              )
            }}
            isLoading={isLoggedIn}
          ></Form>
          <p>Har du glömt ditt lösenord?</p>
          <Link to="/askfornewpwd">Begär ett nytt lösenord</Link>
        </>
      )}
      {isLoggedIn && (
        <Routes>
          <Route path="/Pumplend" element={<Pumplend />} />
          <Route path="/ElectricityBox" element={<ElectricityBoxLend />} />
          <Route path="/Documents" element={<Documentsupload />} />
          <Route path="/WaterCheck" element={<WaterCheck />} />
          <Route path="/Appropve" element={<ApproveUsers />} />
          <Route path="/Settings" element={<Settings />} />
          <Route path="/EditPages" element={<EditPages />} />
        </Routes>
      )}
    </>
  )
}
