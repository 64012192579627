import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import {
  approveUserRequest,
  declineUserRequest,
  getApproveUserRequest,
} from './approveUsersApi'

const initialState = {
  status: REDUX_SLICE_STATUS_IDLE,
  usersToApprove: [],
  successfull: false,
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const approveAsync = createAsyncThunk(
  'approveuser/approve',
  async (approveData) => {
    const response = await approveUserRequest(approveData)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const declineAsync = createAsyncThunk(
  'approveuser/decline',
  async (approveData) => {
    const response = await declineUserRequest(approveData)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const getUsersToApproveAsync = createAsyncThunk(
  'approveuser/getUsersToApprove',
  async (approveData) => {
    const response = await getApproveUserRequest()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const approveUsersSlice = createSlice({
  name: 'approveuser',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSuccess(state) {
      state.successfull = false
    },
    resetUsersToApprove(state) {
      state.usersToApprove = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersToApproveAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getUsersToApproveAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getUsersToApproveAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.usersToApprove = action.payload
      })
      .addCase(approveAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(approveAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(approveAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.successfull = action.payload === 1
      })
      .addCase(declineAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(declineAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(declineAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.successfull = action.payload === 1
      })
  },
})

export const getStatus = (state) => state.approveuser.status
export const getSuccessfull = (state) => state.approveuser.successfull
export const getUsersToApprove = (state) => state.approveuser.usersToApprove
export const { resetSuccess, resetUsersToApprove } = approveUsersSlice.actions

export default approveUsersSlice.reducer
