import { Card } from '../../../../UI/Card/Card'

export const ReadWaterCheck = (props) => {
  return (
    <Card>
      <span>Fastighet: {props.estate}</span>
      <span>
        Avläst värde: {props.value} m<sup>3</sup>
      </span>
      <span>Värdet skickades den: {props.created}</span>
    </Card>
  )
}
