import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getDocumentsListAsync,
  getDocumentsList,
  resetDocumentsList,
  getCategoryList,
} from '../../../features/documents/DocumentsSlice'
import classes from './Documentsview.module.css'
import { domain } from '../../../features/apiConfig/apiConfig'

export const Documentsview = () => {
  const documentsList = useSelector(getDocumentsList)
  const dispatch = useDispatch()
  const categoryList = useSelector(getCategoryList)
  useEffect(() => {
    dispatch(resetDocumentsList())
    dispatch(getDocumentsListAsync())
  }, [])
  return (
    <div className={classes.DocumentsView}>
      <h1>Dokument</h1>
      <div className={classes.DocumentsViewContainer}>
        {categoryList.map((c) => {
          return (
            <div className={classes.DocumentsViewItem}>
              <h3>{c.label}</h3>
              <ul>
                {documentsList.filter((d) => d.documents_category === c.value)
                  .length === 0 && (
                  <li key={`category-none`}>
                    Inga {c.label} finns uppladdade just nu
                  </li>
                )}
                {documentsList
                  .filter((d) => d.documents_category === c.value)
                  .map((d, i) => {
                    return (
                      <div key={`${d.documents_category}-${i}`}>
                        <li key={`${d.documents_category}-${i}`}>
                          <a href={`${domain}documents/get/${d.documents_id}`}>
                            {d.documents_name}
                          </a>
                        </li>
                      </div>
                    )
                  })}
              </ul>
            </div>
          )
        })}
      </div>
    </div>
  )
}
