import { Button } from '../../../../UI/Button/Button'
import { Card } from '../../../../UI/Card/Card'
import classes from './ApproveUser.module.css'

export const ApproveUser = (props) => {
  return (
    <Card>
      <span>Namn: {props.fullName}</span>
      <div className={classes.ApproveButtonsContainer}>
        Skall godkännas:
        <Button clicked={(event) => props.approveHandler(event, props.id)}>
          Godkänn
        </Button>
        <Button clicked={(event) => props.declineHandler(event, props.id)}>
          Neka
        </Button>
      </div>
      <span>Användarnamn: {props.username}</span>
    </Card>
  )
}
