import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import { registerRequest } from './registerApi'

const initialState = {
  status: REDUX_SLICE_STATUS_IDLE,
  isSuccessfull: false,
  isRequestSent: false,
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const registerAsync = createAsyncThunk(
  'register/register',
  async (credentials) => {
    const response = await registerRequest({
      userFirstname: credentials.firstname,
      userLastname: credentials.lastname,
      userName: credentials.username,
      userPassword: credentials.password,
      userEmail: credentials.email,
      userPriviledge: 'normal',
      recaptchaAnswer: credentials.recaptchaAnswer,
    })
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSentState(state) {
      state.isSuccessfull = false
      state.isRequestSent = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(registerAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(registerAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(registerAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isSuccessfull = action.payload === 1
        state.isRequestSent = true
        console.log(state.isRequestSent, state.isRequestSent)
      })
  },
})

export const getSuccessfull = (state) => state.register.isSuccessfull
export const getRequestSent = (state) => state.register.isRequestSent
export const getStatus = (state) => state.register.status != REDUX_SLICE_STATUS_IDLE
export const { resetSentState } = registerSlice.actions

export default registerSlice.reducer
