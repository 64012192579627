import { memo, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  editPageAsync,
  editPageRequestSent,
  editPageSuccess,
  getIsLoading,
  resetEditPageSuccess,
} from '../../../../../features/pages/pagesSlice'
import { Form } from '../../../../UI/Form/Form'
import classes from './EditPage.module.css'

export const EditPage = (props) => {
  const [editPageOriginalState] = useState(props.text)
  const isLoading = useSelector(getIsLoading)
  const dispatch = useDispatch()
  const [editPageState, setEditPageState] = useState({
    pageId: props.pageId,
    pageText: props.text,
  })
  const [errorMessage, setErrorMessage] = useState('')
  const isEditPageRequestSent = useSelector(editPageRequestSent)
  const isEditPageSuccess = useSelector(editPageSuccess)

  const formDefinition = [
    { type: 'label', labelText: 'Sidtext' },
    {
      type: 'pageEdit.text',
      editorHandler: (data) => {
        setEditPageState((prev) => {
          return { ...prev, pageText: data }
        })
      },
      text: props.text,
    },
  ]

  useEffect(() => {
    props.messageTrigger()
    return () => {
      dispatch(resetEditPageSuccess())
      setErrorMessage('')
    }
  }, [isEditPageRequestSent, isEditPageSuccess])

  const buttonClickedHandler = useCallback(
    (event, editPageOriginalState, editPageState) => {
      event.preventDefault()
      if (!allDataSet()) {
        setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
        return
      }
      if (editPageOriginalState == editPageState.pageText) {
        setErrorMessage(
          'Du har inte gjort någon ändring, därför sparas den inte.',
        )
        return
      }
      dispatch(editPageAsync(editPageState))
    },
    [editPageState.pageText],
  )

  const allDataSet = useCallback(() => editPageState.pageText !== '', [
    editPageState.pageText,
  ])

  return (
    <>
      <Form
        formDefinition={formDefinition}
        buttonText={props.buttonText}
        clicked={(event) =>
          buttonClickedHandler(event, editPageOriginalState, editPageState)
        }
        isLoading={isLoading}
      ></Form>
    </>
  )
}
