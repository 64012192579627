import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '../../../UI/Form/Form'
import {
  getIsLoading,
  getIsRequestFulfilled,
  getIsRequestSubmitted,
  resetStatusBools,
  sendPasswordResetEmailAsync,
} from '../../../../features/passwordReset/passwordResetSlice'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'

export const AskForNewPwd = () => {
  const dispatch = useDispatch()
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    email: '',
  })
  const isLoading = useSelector(getIsLoading)
  const isRequestSubmitted = useSelector(getIsRequestSubmitted)
  const isRequestFulfilled = useSelector(getIsRequestFulfilled)
  const [resetForm, setResetForm] = useState(false)

  const formDefinition = [
    { type: 'label', labelText: 'Mejl för lösenordsåterställning' },
    {
      type: 'text',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, email: event.target.value }
        })
      },
    },
  ]

  const buttonClickedHandler = (event) => {
    event.preventDefault()
    setErrorMessage('')
    setResetForm(false)
    dispatch(resetStatusBools())
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }
    dispatch(sendPasswordResetEmailAsync(formData))
  }

  const allDataSet = () => {
    let isValid = true
    if (formData.email === '') {
      isValid = false
    }

    return isValid
  }

  useEffect(() => {
    if (isRequestFulfilled) {
      toast.success('Återställningsmejlet är skickat.', toastifyConfig)
      dispatch(resetStatusBools())
      setFormData({ email: '' })
      setResetForm(true)
    }
  }, [isRequestFulfilled])

  useEffect(() => {
    if (errorMessage.length != 0) {
      toast.error(errorMessage, toastifyConfig)
    }
    return () => {
      setErrorMessage('')
    }
  }, [errorMessage])

  return (
    <div>
      <div>
        <h1>Begär lösenordsåterställning</h1>
        <p>
          Nedan anges den epostadress som användes vid registreringen på
          hemsidan. Titta sedan efter ett mejl från avsändaren info@tarno.nu och
          änmesraden Återställning av lösenord på tarno.nu.
        </p>
        <Form
          formDefinition={formDefinition}
          buttonText="Begär lösenordsåterställning"
          clicked={(event) => {
            buttonClickedHandler(event)
          }}
          isLoading={isLoading}
          reset={resetForm}
        ></Form>
      </div>
    </div>
  )
}
