import { domain } from '../apiConfig/apiConfig'

export const updatePasswordRequest = async ({
  userPassword,
  userId,
  sessionId,
}) => {
  const settingsData = JSON.stringify({
    user_password: userPassword,
  })
  const result = await fetch(`${domain}users/updatepassword/${userId}`, {
    method: 'put',
    headers: { Authorization: 'Bearer ' + sessionId },
    body: settingsData,
  })
  return result.json()
}

export const updateEmailRequest = async ({ userEmail, userId, sessionId }) => {
  console.log(userEmail, userId, sessionId)
  const settingsData = JSON.stringify({
    user_email: userEmail,
  })
  const result = await fetch(`${domain}users/updateemail/${userId}`, {
    method: 'put',
    headers: { Authorization: 'Bearer ' + sessionId },
    body: settingsData,
  })
  return result.json()
}
