import { domain } from '../apiConfig/apiConfig'

export const createElectricityBoxLend = async (elBoxLend) => {
  const dataToApi = {
    lends_start: elBoxLend.lendStart,
    lends_lended_by: elBoxLend.lendedBy,
    session_id: elBoxLend.sessionId,
    lends_estate: elBoxLend.estate,
    lends_lended_el_box: elBoxLend.lendedElBoxId,
  }
  const result = await fetch(
    `${domain}electricityboxes/createelectricityboxlends`,
    {
      method: 'post',
      body: JSON.stringify(dataToApi),
    },
  )
  return result.json()
}

export const getAllElectricityBoxLends = async () => {
  const result = await fetch(`${domain}electricityboxes/getelectricityboxlends`)
  return result.json()
}
export const getAllElectricityBoxes = async () => {
  console.log('get all free to lend')
  const result = await fetch(`${domain}electricityboxes/getall`)
  return result.json()
}

export const endElectricityBoxLend = async (data) => {
  console.log('in', data)
  const dataToApi = {
    el_box_lends_id: data.electricityBoxLendId,
    session_id: data.sessionId,
    lends_end: data.dateOfLendEnd,
    el_boxes_id: data.elBoxId,
  }

  const result = await fetch(
    `${domain}electricityboxes/electricityboxlenddone/${data.electricityBoxLendId}`,
    {
      method: 'put',
      body: JSON.stringify(dataToApi),
    },
  )
  return result.json()
}
