import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classes from './EditPages.module.css'
import {
  getPagesAsync,
  pages,
  getIsLoading,
  editPageRequestSent,
  editPageSuccess,
  resetEditPageSuccess,
} from '../../../../features/pages/pagesSlice'
import { EditPage } from './EditPage/EditPage'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'
export const EditPages = (props) => {
  const dispatch = useDispatch()
  const allPages = useSelector(pages)
  const buttonText = 'Uppdatera sida'
  const isLoading = useSelector(getIsLoading)
  const [editPageState, setEditPageState] = useState({
    pageId: props.pageId,
    pageText: props.text,
  })
  const [errorMessage, setErrorMessage] = useState('')
  const isEditPageRequestSent = useSelector(editPageRequestSent)
  const isEditPageSuccess = useSelector(editPageSuccess)

  const formHandler = (data) => {
    setEditPageState((prev) => {
      return { ...prev, pageText: data }
    })
  }

  const displayMessages = () => {
    if (isEditPageRequestSent && isEditPageSuccess) {
      toast.success('Sidan uppdatterades', toastifyConfig)
    } else if (isEditPageRequestSent && !isEditPageSuccess) {
      toast.error('Något gick fel, sidan uppdatterades inte', toastifyConfig)
    } else if (errorMessage.length !== 0) {
      toast.error(errorMessage, toastifyConfig)
    }
    return () => {
      dispatch(resetEditPageSuccess())
      setErrorMessage('')
    }
  }

  useEffect(() => {
    dispatch(getPagesAsync())
  }, [])

  return (
    <>
      <h1>Redigera sidinnehåll</h1>
      <p>
        Klicka på pilen för sidan som skall redigeras, fyll i text, klicka på
        {` ${buttonText}`}.
      </p>
      {allPages.map((x) => (
        <details key={x.pages_id}>
          <summary>{x.pages_name}</summary>
          <EditPage
            pageId={x.pages_id}
            text={x.pages_text}
            buttonText={buttonText}
            isLoading={isLoading}
            formHandler={formHandler}
            messageTrigger={displayMessages}
          />
        </details>
      ))}
    </>
  )
}
