import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSessionId,
  getUserInfo,
  getUserInfoAsync,
} from '../../../features/login/loginSlice'
import {
  getRequestSent,
  getSuccessfull,
  resetSentState,
  updateEmailAsync,
  updatePasswordAsync,
} from '../../../features/settings/settingsSlice'
import { Form } from '../../UI/Form/Form'
import { toast } from 'react-toastify'

import classes from './Settings.module.css'
import { toastifyConfig } from '../../../features/toastifyConfig/toastifyConfig'
export const Settings = () => {
  const dispatch = useDispatch()
  const isSuccessfullRequest = useSelector(getSuccessfull)
  const isRequestSent = useSelector(getRequestSent)
  const [errorMessage, setErrorMessage] = useState('')
  const [credentialsState, setCredendialsState] = useState({
    password: '',
    passwordRepeat: '',
    email: '',
  })
  const userInfo = useSelector(getUserInfo)
  const sessionId = useSelector(getSessionId)
  const [resetForm, setResetForm] = useState(false)

  const formDefinitionPassword = [
    { type: 'label', labelText: 'Nytt Lösenord' },

    {
      type: 'password',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, password: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Upprepa nytt Lösenord' },

    {
      type: 'password',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, passwordRepeat: event.target.value }
        })
      },
    },
  ]

  const formDefinitionEmail = [
    { type: 'label', labelText: 'Ny epostadress' },

    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, email: event.target.value }
        })
      },
    },
  ]
  const buttonClickedPasswordHandler = (event) => {
    event.preventDefault()
    if (!allDataSetPassword()) {
      setErrorMessage('Kontrollera att lösenordsfälten är ifyllda.')
      return
    }
    dispatch(resetSentState())
    dispatch(
      updatePasswordAsync({
        userPassword: credentialsState.password,
        userId: userInfo.users_id,
        sessionId: sessionId,
      }),
    )
  }

  const buttonClickedEmailHandler = (event) => {
    event.preventDefault()
    if (!allDataSetEmail()) {
      setErrorMessage('Kontrollera att epost är ifyllt.')
      return
    }
    dispatch(resetSentState())
    dispatch(
      updateEmailAsync({
        userEmail: credentialsState.email,
        userId: userInfo.users_id,
        sessionId: sessionId,
      }),
    )
  }

  const allDataSetPassword = () => {
    let isValid = true

    if (credentialsState.password !== credentialsState.passwordRepeat) {
      isValid = false
    }

    return isValid
  }

  const allDataSetEmail = () => {
    let isValid = true

    if (credentialsState.email === '') {
      isValid = false
    }

    return isValid
  }

  useEffect(() => {
    dispatch(resetSentState())
  }, [])

  useEffect(() => {
    if (isRequestSent && isSuccessfullRequest) {
      toast.success('Ändringarna sparades', toastifyConfig)
      setCredendialsState({
        password: '',
        passwordRepeat: '',
        email: '',
      })
      setResetForm(true)
      dispatch(getUserInfoAsync(sessionId))
    } else if (isRequestSent && !isSuccessfullRequest) {
      toast.error('Tyvärr, ändringarna sparades inte, något gick fel', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 10000,
      })
    }
  }, [isRequestSent, isSuccessfullRequest, sessionId, dispatch])
  useEffect(() => {}, [resetForm])

  useEffect(() => {
    if (errorMessage.length != 0) {
      toast.error(errorMessage, toastifyConfig)
    }
    return () => {
      setErrorMessage('')
    }
  }, [errorMessage])

  return (
    <>
      <h1>Inställningar</h1>
      <h2>Uppdatera lösenord</h2>
      <Form
        formDefinition={formDefinitionPassword}
        buttonText="Uppdatera ditt lösenord"
        clicked={(event) => buttonClickedPasswordHandler(event)}
        reset={resetForm}
      ></Form>
      <h2>Uppdatera epost</h2>
      {
        <p>
          {userInfo.users_email !== ''
            ? `Nuvarande epost är ${userInfo.users_email}`
            : `Ingen epost angiven`}
        </p>
      }
      <Form
        formDefinition={formDefinitionEmail}
        buttonText="Uppdatera din epost"
        clicked={(event) => buttonClickedEmailHandler(event)}
        reset={resetForm}
      ></Form>
    </>
  )
}
