import { API_CONFIG_PROD, API_CONFIG_TESTSITE, API_CONFIG_LOCAL } from "../constantsConfig/constantsConfig"
const environment = API_CONFIG_PROD
let domain = ''

switch (environment) {
  case API_CONFIG_LOCAL:
    domain = 'http://localhost/GitHub/TarnoVa/api/'
    break
  case API_CONFIG_TESTSITE:
    domain = 'https://tarno.berntorp.com/TarnoVa/api/'
    break
  case API_CONFIG_PROD:
    domain = 'https://tarno.nu/api/'
    break
  default:
    domain = 'http://localhost/GitHub/TarnoVa/api/'
}

export { domain, environment, API_CONFIG_PROD }
