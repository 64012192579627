import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  changeCategoryAsync,
  deleteDocumentAsync,
  getCategoryList,
  getDeleteSuccess,
  getErrors,
  getSuccess,
  getUploadedDocuments,
  getUploadedDocumentsListAsync,
  getUploadProgress,
  resetDeleteSuccess,
  resetSuccess,
  resetUploadedDocuments,
  uploadDocumentAsync,
} from '../../../../features/documents/DocumentsSlice'
import { Form } from '../../../UI/Form/Form'
import classes from './Documentsupload.module.css'
import { Uploadeddocument } from './Uploadeddocument/Uploadeddocument'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'
import { getUserInfo } from '../../../../features/login/loginSlice'
import { useNavigate } from 'react-router'

export const Documentsupload = (props) => {
  const [formData, setFormData] = useState({
    fileContents: null,
    fileCategory: 'stadgar',
  })
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const [extraColumn, setExtraColumn] = useState(false)
  const [updateColumnCount, setUpdateColumnCount] = useState(false)

  const uploadedDocuments = useSelector(getUploadedDocuments)
  const isUploadInProgress = useSelector(getUploadProgress)
  const uploadSuccess = useSelector(getSuccess)
  const errors = useSelector(getErrors)
  const deleteSuccess = useSelector(getDeleteSuccess)
  const categoryList = useSelector(getCategoryList)
  const userInfo = useSelector(getUserInfo)
  const [resetForm, setResetForm] = useState(false)

  const formDefinition = [
    { type: 'label', labelText: 'Välj dokument att ladda upp' },
    {
      type: 'file',
      allowedFileType: 'application/pdf',
      handler: (event) => {
        const fileFromInput = event.target.files[0]
        setFormData((prev) => {
          return {
            ...prev,
            fileContents: fileFromInput,
          }
        })
      },
    },
    { type: 'label', labelText: 'Kategori' },
    {
      type: 'select',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, fileCategory: event.target.value }
        })
      },
      selectOptions: categoryList.map((c) => {
        return { optionValue: c.value, optionText: c.label }
      }),
    },
  ]

  const allDataSet = () => {
    let isValid = true
    if (formData.fileContents === '') {
      isValid = false
    }

    return isValid
  }

  const buttonClickedHandler = (event) => {
    event.preventDefault()
    setErrorMessage('')
    setResetForm(false)
    dispatch(resetSuccess())
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }

    dispatch(uploadDocumentAsync(formData))
  }

  useEffect(() => {
    dispatch(getUploadedDocumentsListAsync())
  }, [])

  useEffect(() => {
    if (uploadSuccess || deleteSuccess) {
      if (uploadSuccess) {
        toast.success('Dokumentet är nu uppladdat', toastifyConfig)
        setResetForm(true)
      }
      dispatch(resetUploadedDocuments())
      dispatch(getUploadedDocumentsListAsync())
    }

    return () => {
      dispatch(resetSuccess())
    }
  }, [uploadSuccess, deleteSuccess])

  useEffect(() => {}, [resetForm])

  useEffect(() => {
    if (errorMessage.length != 0) {
      toast.error(errorMessage, toastifyConfig)
    }

    return () => {
      setErrorMessage('')
    }
  }, [errorMessage, errors])

  const updateCategoryHandler = (event, documentId, newCategory) => {
    event.preventDefault()
    dispatch(changeCategoryAsync({ documentId, newCategory }))
  }

  const clickedRemoveHandler = (event, documentId) => {
    event.preventDefault()
    dispatch(resetDeleteSuccess())
    dispatch(deleteDocumentAsync(documentId))
  }

  return (
    <div className={classes.DocumentsUpload}>
      <div className={classes.UploadForm}>
        <h1>Dokumentuppladdning</h1>
        <Form
          formDefinition={formDefinition}
          buttonText="Ladda upp dokumentet"
          clicked={(event) => {
            buttonClickedHandler(event)
          }}
          isLoading={isUploadInProgress}
          reset={resetForm}
        ></Form>
      </div>
      <div className={classes.DocumentsList}>
        <h1>Uppladdade dokument</h1>
        {uploadedDocuments.length === 0 && (
          <p>Just nu finns det inga uppladdade dokument</p>
        )}
        {uploadedDocuments.map((d) => {
          return (
            <Uploadeddocument
              key={d.documents_id}
              documentId={d.documents_id}
              name={d.documents_name}
              id={d.documents_id}
              category={d.documents_category}
              sessionId={props.sessionId}
              updateCategoryHandler={updateCategoryHandler}
              removeHandler={clickedRemoveHandler}
            />
          )
        })}
      </div>
      <div></div>
    </div>
  )
}
