import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./MenuButton.module.css";

const MenuButton = (props) => (
  <span onClick={props.clicked} className={classes.MenuButton}>
    <FontAwesomeIcon icon={faBars} size="2x" />
  </span>
);

export default MenuButton;