import { domain } from '../apiConfig/apiConfig'

export const approveUserRequest = async (approveData) => {
  const dataToApi = {
    approval_of_users_id: approveData.approvedId,
    is_approved: approveData.isApproved ? 1 : 0,
  }

  const result = await fetch(`${domain}users/approveusers`, {
    method: 'post',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}
export const declineUserRequest = async (approveData) => {
  const result = await fetch(
    `${domain}users/deletedeniedapproval/${approveData.approvedId}`,
    { method: 'delete' },
  )
  return result.json()
}

export const getApproveUserRequest = async (approveData) => {
  const result = await fetch(`${domain}users/getuserstoapprove`)
  return result.json()
}
