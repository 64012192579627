import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  resetPumpLends,
  endPumpLendAsync,
  getEndPumpLendSuccess,
  resetEndPumpLendSuccess,
  getIsLoading,
  getPumpsFreeToLend,
  resetPumpLendSuccess,
  getIsPumpsFreeToLendInitiated,
  getIsEndingPumpLend,
} from '../../../../features/pump/PumpSlice'
import { getSessionId } from '../../../../features/login/loginSlice'
import {
  createPumpLendAsync,
  getCreatePumpLendSuccess,
  getPumpLends,
  getPumpLendsAsync,
  getSparePumpsAsync,
} from '../../../../features/pump/PumpSlice'
import { Form } from '../../../UI/Form/Form'
import { Lendedpump } from './LendedPump/LendedPump'
import classes from './Pumplend.module.css'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'

export const Pumplend = () => {
  const dispatch = useDispatch()
  const sessionId = useSelector(getSessionId)
  const pumpLends = useSelector(getPumpLends)
  const isPumpsFreeToLendInitiated = useSelector(getIsPumpsFreeToLendInitiated)
  const successCreatePumpLend = useSelector(getCreatePumpLendSuccess)
  const successPumpLendEnd = useSelector(getEndPumpLendSuccess)
  const isLoading = useSelector(getIsLoading)
  const isEndingPumpLend = useSelector(getIsEndingPumpLend)
  const pumpsFreeToLend = useSelector(getPumpsFreeToLend)

  const [errorMessage, setErrorMessage] = useState('')
  const [resetForm, setResetForm] = useState(false)
  const [formData, setFormData] = useState({
    lendedBy: '',
    faultDescription: '',
    lendStart: '',
    lendedPump: 1,
  })

  const formDefinition = [
    { type: 'label', labelText: 'Lånas av' },
    {
      type: 'text',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendedBy: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Beskrivning av fel' },
    {
      type: 'textarea',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, faultDescription: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'När påbörjades lånet' },
    {
      type: 'date',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendStart: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Pump att låna' },
    {
      type: 'select',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendedPump: event.target.value }
        })
      },
      selectOptions: pumpsFreeToLend.map((sparePump) => {
        return {
          optionValue: sparePump.spare_pumps_id,
          optionText: sparePump.spare_pumps_name,
        }
      }),
    },
  ]

  const allDataSet = () => {
    let isValid = true
    if (formData.lendedBy === '') {
      isValid = false
    }
    if (formData.faultDescription === '') {
      isValid = false
    }
    if (formData.lendStart === '') {
      isValid = false
    }
    return isValid
  }

  const buttonClickedHandler = (event) => {
    event.preventDefault()
    setErrorMessage('')
    setResetForm(false)
    dispatch(resetPumpLendSuccess())
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }

    dispatch(createPumpLendAsync(formData))
  }

  const buttonEndLendHandler = (event, pumpLendId, sparePumpId) => {
    event.preventDefault()
    const dateOfLendEnd = new Date()
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ')

    dispatch(resetEndPumpLendSuccess())
    dispatch(
      endPumpLendAsync({
        dateOfLendEnd,
        sessionId,
        pumpLendId: pumpLendId,
        sparePumpId,
      }),
    )
  }

  useEffect(() => {
    dispatch(getSparePumpsAsync())
    dispatch(getPumpLendsAsync())
  }, [])

  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, sessionId }
    })
  }, [sessionId])

  useEffect(() => {
    if (successCreatePumpLend) {
      toast.success('Pumplånet sparades', toastifyConfig)
      setFormData({
        lendedBy: '',
        faultDescription: '',
        lendStart: '',
        lendedPump: 1,
      })
      setResetForm(true)
      dispatch(resetPumpLends())
      dispatch(getPumpLendsAsync())
    } else if (successPumpLendEnd) {
      dispatch(resetPumpLends())
      dispatch(getPumpLendsAsync())
    }
    return () => {
      dispatch(resetEndPumpLendSuccess())
      dispatch(resetPumpLendSuccess())
    }
  }, [successCreatePumpLend, successPumpLendEnd])

  useEffect(() => {}, [resetForm])

  useEffect(() => {
    errorMessage.length !== 0 && toast.error(errorMessage, toastifyConfig)
  }, [errorMessage])

  useEffect(() => {
    if (isPumpsFreeToLendInitiated && pumpsFreeToLend.length === 1) {
      setFormData((prev) => {
        return { ...prev, lendedPump: pumpsFreeToLend[0].spare_pumps_id }
      })
    }
  }, [isPumpsFreeToLendInitiated])

  return (
    <div className={classes.PumpLend}>
      <div>
        <p>
          Här kan du låna och avsluta lån av en reservpump.
          <br />
          <br />
          <strong style={{ color: 'red' }}>OBS: </strong>
          <strong>
            Vid återlämnandet skall reservpumpen vara väl rengjord.
          </strong>
        </p>
        {isPumpsFreeToLendInitiated && pumpsFreeToLend.length == 0 && (
          <p className={classes.Error}>
            Alla pumpar är utlånade, kom tillbaka senare
          </p>
        )}
        {isPumpsFreeToLendInitiated == false && <p>Pumpar att låna hämtas</p>}
        {isPumpsFreeToLendInitiated == true && (
          <Form
            disabled={pumpsFreeToLend.length == 0}
            formDefinition={formDefinition}
            buttonText="Låna pump"
            clicked={(event) => {
              buttonClickedHandler(event)
            }}
            isLoading={!isEndingPumpLend && isLoading}
            reset={resetForm}
          ></Form>
        )}
      </div>
      <div>
        <h1>Registrerade pumplån</h1>
        {pumpLends.length === 0 && <p>Inga pumplån finns just nu.</p>}
        {pumpLends.map((pl) => {
          return (
            <Lendedpump
              key={pl.pump_lends_id}
              sparePumpId={pl.spare_pumps_id}
              sparePumpName={pl.spare_pumps_name}
              pumpLendId={pl.information.pump_lends_id}
              lendedBy={pl.information.pump_lends_lended_by}
              lendedById={pl.information.pump_lends_users_id}
              lendStart={pl.information.pump_lends_start}
              lendEnd={pl.information.pump_lends_end}
              faultDescription={pl.information.pump_lends_description}
              lendEndButtonClick={buttonEndLendHandler}
            />
          )
        })}
      </div>
    </div>
  )
}
