import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import {
  approveAsync,
  declineAsync,
  getSuccessfull,
  getUsersToApprove,
  getUsersToApproveAsync,
  resetSuccess,
  resetUsersToApprove,
} from '../../../../features/approveUsers/approveUsersSlice'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'
import { ApproveUser } from './ApproveUser/ApproveUser'
export const ApproveUsers = () => {
  const dispatch = useDispatch()
  const usersToApprove = useSelector(getUsersToApprove)
  const success = useSelector(getSuccessfull)

  const approveHandler = (event, id) => {
    dispatch(
      approveAsync({
        isApproved: 1,
        approvedId: id,
      }),
    )
  }
  const declineHandler = (event, id) => {
    dispatch(
      declineAsync({
        approvedId: id,
      }),
    )
  }

  useEffect(() => {
    dispatch(getUsersToApproveAsync())
  }, [])
  useEffect(() => {
    if (success) {
      toast.success('Användarens begäran hanterades korrekt', toastifyConfig)

      dispatch(resetUsersToApprove())
      dispatch(getUsersToApproveAsync())
    }
    return () => dispatch(resetSuccess())
  }, [success])

  return (
    <>
      <h1>Nya användare att godkänna</h1>
      {!usersToApprove.length && (
        <p>Det finns inga nya användare att godkänna</p>
      )}
      {usersToApprove.map((x) => (
        <ApproveUser
          key={x.approval_of_users_id}
          id={x.approval_of_users_id}
          fullName={`${x.approval_of_users_first_name} ${x.approval_of_users_last_name}`}
          username={x.approval_of_users_username}
          approveHandler={approveHandler}
          declineHandler={declineHandler}
        />
      ))}
    </>
  )
}
