import classes from './Input.module.css'
import ReCAPTCHA from 'react-google-recaptcha'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import parse from 'html-react-parser'
import { useEffect } from 'react'

export const Input = (props) => {
  useEffect(() => {
    console.log('Input, props.inputError updated', props.inputError)
  }, [props.inputError])
  let inputElement = ''
  const isErrorPresent =
    props.inputErrors?.filter((x) => x.fieldName == props.fieldName)[0]?.error !=
    ''
  const errorMessage =
    props.inputErrors?.filter((x) => x.fieldName == props.fieldName)[0]?.error
  switch (props.type) {
    case 'textarea':
      inputElement = (
        <>
          <textarea
            className={classes.TextAreaInput}
            onChange={props.change}
            disabled={props.disabled}
          ></textarea>
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
      break
    case 'label':
      inputElement = <label>{parse(props.labelText)}</label>
      break
    case 'select':
      const selectOptions =
        props.selectOptions &&
        props.selectOptions.map((element, i) => {
          let returnValue
          if (typeof element != 'undefined') {
            returnValue = (
              <option key={i} value={element.optionValue}>
                {element.optionText}
              </option>
            )
          }
          return returnValue
        })
      inputElement = (
        <>
          <select
            className={classes.InputElement}
            onChange={props.change}
            disabled={props.disabled}
          >
            {selectOptions}
          </select>
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )

      break
    case 'file':
      inputElement = (
        <>
          <input
            type={props.type}
            className={classes.InputElement}
            onChange={props.change}
            accept={props.allowedFileType}
            disabled={props.disabled}
          />
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
      break
    case 'date':
      inputElement = (
        <>
          <input
            type={props.type}
            className={classes.InputElement}
            onChange={props.change}
            disabled={props.disabled}
          />
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
      break
    case 'recaptcha':
      inputElement = (
        <ReCAPTCHA
          sitekey="6Lc5WLUkAAAAAEsx_fdmFbcn7TBVnes3XOvIHO9V"
          onChange={props.change}
        />
      )
      break
    case 'pageEdit.text':
      inputElement = (
        <CKEditor
          editor={ClassicEditor}
          data={props.text}
          onReady={(editor) => {
            // You can store the "editor" and use when it is needed.
            console.log('Editor is ready to use!', editor)
          }}
          onChange={(event, editor) => {
            const data = editor.getData()
            props.change(data)
          }}
        />
      )
      break
    case 'number':
      inputElement = (
        <>
          <input
            type={props.type}
            className={classes.InputElement}
            onChange={props.change}
            disabled={props.disabled}
          />
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
      break
    case 'checkbox':
      inputElement = (
        <>
          <div className={classes.CheckboxContainer}>
            <input
              type={props.type}
              onChange={props.change}
              disabled={props.disabled}
              checked={props.value}
            />
            <span className={classes.Checkmark}></span>
          </div>
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
      break
    default:
      inputElement = (
        <>
          <input
            type={props.type}
            className={classes.InputElement}
            onChange={props.change}
            {...(props.type === 'file' && { accept: props.allowedFileType })}
            {...(props.placeHolder && { placeholder: props.placeHolder })}
            disabled={props.disabled}
          />
          {isErrorPresent && <p className={classes.error}>{errorMessage}</p>}
        </>
      )
  }
  return <div className={classes.Input}>{inputElement}</div>
}
