import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getRequestSent,
  getSuccessfull,
  getStatus,
  registerAsync,
  resetSentState,
} from '../../../features/register/registerSlice'
import { Form } from '../../UI/Form/Form'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../features/toastifyConfig/toastifyConfig'

export const Register = () => {
  const dispatch = useDispatch()
  const isSuccessfullRequest = useSelector(getSuccessfull)
  const isRequestSent = useSelector(getRequestSent)
  const [credentialsState, setCredendialsState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    passwordRepeat: '',
    recaptchaAnswer: '',
  })
  const isLoading = useSelector(getStatus)
  const [errorMessage, setErrorMessage] = useState('')
  const [resetForm, setResetForm] = useState(false)

  const formDefinition = [
    { type: 'label', labelText: 'Förnamn' },
    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, firstName: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Efternamn' },
    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, lastName: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Användarnamn' },

    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, username: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Epost' },

    {
      type: 'text',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, email: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Lösenord' },

    {
      type: 'password',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, password: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Upprepa Lösenord' },

    {
      type: 'password',
      handler: (event) => {
        setCredendialsState((prev) => {
          return { ...prev, passwordRepeat: event.target.value }
        })
      },
    },
    {
      type: 'recaptcha',
      handler: (answer) => {
        setCredendialsState((prev) => {
          return { ...prev, recaptchaAnswer: answer }
        })
      },
    },
  ]
  const buttonClickedHandler = (event, ...userObject) => {
    event.preventDefault()
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }
    dispatch(resetSentState())
    dispatch(
      registerAsync({
        firstname: userObject[0],
        lastname: userObject[1],
        username: userObject[2],
        email: userObject[3],
        password: userObject[4],
        recaptchaAnswer: userObject[5],
      }),
    )
  }

  const allDataSet = () =>
    credentialsState.password === credentialsState.passwordRepeat &&
    Object.values(credentialsState).filter((x) => x !== '').length ===
      Object.keys(credentialsState).length

  useEffect(() => {
    dispatch(resetSentState())
  }, [])
  useEffect(() => {
    if (isRequestSent && isSuccessfullRequest) {
      toast.success(
        'Din registrering kommer att granskas, du får ett mejl när den är godkänd',
        toastifyConfig,
      )
      setCredendialsState({
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        passwordRepeat: '',
        recaptchaAnswer: '',
      })
      setResetForm(() => true)
    } else if (isRequestSent && !isSuccessfullRequest) {
      toast.error(
        'Tyvärr, registreringen lyckades inte, något gick fel',
        toastifyConfig,
      )
    }
    return () => {
      dispatch(resetSentState())
    }
  }, [isRequestSent, isSuccessfullRequest])

  useEffect(() => {}, resetForm)

  useEffect(() => {
    if (errorMessage.length != 0) {
      toast.error(errorMessage, toastifyConfig)
    }
    return () => {
      setErrorMessage('')
    }
  }, [errorMessage])

  return (
    <>
      <h1>Registrera dig</h1>
      <Form
        formDefinition={formDefinition}
        buttonText="Registrera dig"
        clicked={(event) =>
          buttonClickedHandler(
            event,
            credentialsState.firstName,
            credentialsState.lastName,
            credentialsState.username,
            credentialsState.email,
            credentialsState.password,
            credentialsState.recaptchaAnswer,
          )
        }
        isLoading={isLoading}
        reset={resetForm}
      ></Form>
    </>
  )
}
