import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Form } from '../../../UI/Form/Form'
import {
  getIsLoading,
  getIsPasswordUpdated,
  getIsRequestFulfilled,
  getIsRequestSubmitted,
  getIsVerificationFulfilled,
  getIsVerifying,
  getPasswordResetCode,
  performPasswordResetAsync,
  resetIsVerificationFulfilled,
  resetStatusBools,
  sendPasswordResetEmailAsync,
  setPasswordResetCode,
  verifyPasswordResetCodeAsync,
} from '../../../../features/passwordReset/passwordResetSlice'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'
import { useParams } from 'react-router'
import { verifyPasswordResetCode } from '../../../../features/passwordReset/passwordResetApi'
import { Link } from 'react-router-dom'

export const ResetPassword = () => {
  const dispatch = useDispatch()
  const { passwordResetCode } = useParams()
  const [errorMessage, setErrorMessage] = useState('')
  const [formData, setFormData] = useState({
    password: '',
    passwordRepeat: '',
  })
  const isLoading = useSelector(getIsLoading)
  const userPasswordResetCode = useSelector(getPasswordResetCode)
  const isRequestFulfilled = useSelector(getIsRequestFulfilled)
  const isVerificationFulfilled = useSelector(getIsVerificationFulfilled)
  const isVerifying = useSelector(getIsVerifying)
  const isPasswordUpdated = useSelector(getIsPasswordUpdated)
  const [resetForm, setResetForm] = useState(false)

  const formDefinition = [
    { type: 'label', labelText: 'Ange ditt lösenord' },
    {
      type: 'password',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, password: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Repetera lösenord' },
    {
      type: 'password',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, passwordRepeat: event.target.value }
        })
      },
    },
  ]

  const buttonClickedHandler = (event) => {
    event.preventDefault()
    setErrorMessage('')
    setResetForm(false)
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }

    dispatch(
      performPasswordResetAsync({
        password: formData.password,
        passwordResetCode,
      }),
    )
    dispatch(resetStatusBools())
  }

  const allDataSet = () => {
    let isValid = true
    if (formData.password !== formData.passwordRepeat) {
      isValid = false
    }

    return isValid
  }

  useEffect(() => {
    dispatch(setPasswordResetCode(passwordResetCode))
  })

  useEffect(() => {
    dispatch(setPasswordResetCode(passwordResetCode))
    if (userPasswordResetCode !== '') {
      dispatch(
        verifyPasswordResetCodeAsync({
          passwordResetCode: userPasswordResetCode,
        }),
      )
    }
  }, [userPasswordResetCode])

  useEffect(() => {}, [isRequestFulfilled, isVerifying])

  useEffect(() => {
    console.log(isPasswordUpdated)
    if (isPasswordUpdated) {
      dispatch(resetIsVerificationFulfilled())
      setResetForm(true)
    }
  }, [isPasswordUpdated, dispatch])

  return (
    <div>
      <div>
        {isLoading && isVerifying && <p>Verifieringskoden kontrolleras.</p>}
        {!isLoading && !isVerificationFulfilled && !isPasswordUpdated && (
          <>
            <p>Verifieringskoden har använts eller har upphört att gälla.</p>
            <Link to="/askfornewpwd">Begär ett nytt lösenord</Link>
          </>
        )}
        {!isLoading && isPasswordUpdated && (
          <>
            <p>Lösenordet uppdaterades, du kan nu logga in</p>
            <Link to="/Login">Till inloggning</Link>
          </>
        )}
        {!isLoading && isVerificationFulfilled && !isVerifying && (
          <>
            <h1>Fyll i ditt nya lösenord</h1>
            <p>Här nedan fyller du i ditt nya lösenord.</p>
            <Form
              formDefinition={formDefinition}
              buttonText="Skapa nytt lösenord"
              clicked={(event) => {
                buttonClickedHandler(event)
              }}
              isLoading={isLoading}
              reset={resetForm}
            ></Form>
          </>
        )}
      </div>
    </div>
  )
}
