import { domain } from '../apiConfig/apiConfig'

export const verifyPasswordResetCode = async (data) => {
  console.log('DOMAIN', domain)
  const loginData = JSON.stringify({
    pwd_reset_code: data.passwordResetCode,
  })
  const result = await fetch(`${domain}passwordreset/verifypasswordresetcode`, {
    method: 'post',
    body: loginData,
  })
  return result.json()
}

export const sendPasswordResetEmail = async (formData) => {
  const loginData = JSON.stringify({
    pwd_reset_email: formData.email,
  })
  const result = await fetch(`${domain}passwordreset/sendpasswordresetemail`, {
    method: 'post',
    body: loginData,
  })
  return result.json()
}

export const performPasswordReset = async (data) => {
  const loginData = JSON.stringify({
    pwd_reset_code: data.passwordResetCode,
    pwd_reset_new_pwd: data.password,
  })
  const result = await fetch(`${domain}passwordreset/performpasswordreset`, {
    method: 'post',
    body: loginData,
  })
  return result.json()
}
