import { domain } from '../apiConfig/apiConfig'

export const getAllWaterChecks = async () => {
  const result = await fetch(`${domain}watercheck/getall`)
  return result.json()
}
export const createWaterCheck = async (data) => {
  const dataToApi = {
    water_check_estate: data.estate,
    water_check_value: data.value,
    water_check_contact_person: data.contactPerson,
    water_check_email: data.email,
    turn_off_water_at_winter: data.turnOffWaterAtWinter,
  }
  const result = await fetch(`${domain}watercheck/create`, {
    method: 'post',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}
