import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import {
  performPasswordReset,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
} from './passwordResetApi'

const initialState = {
  status: REDUX_SLICE_STATUS_IDLE,
  passwordResetCode: '',
  isVerificationFulfilled: false,
  isRequestSubmitted: false,
  isRequestFulfilled: false,
  isVerifying: false,
  isPasswordUpdated: false,
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const sendPasswordResetEmailAsync = createAsyncThunk(
  'passwordReset/sendPasswordResetEmail',
  async (formData) => {
    const response = await sendPasswordResetEmail(formData)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const performPasswordResetAsync = createAsyncThunk(
  'passwordReset/performPasswordReset',
  async (data) => {
    const response = await performPasswordReset(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const verifyPasswordResetCodeAsync = createAsyncThunk(
  'passwordReset/verifyPasswordResetCode',
  async (data) => {
    const response = await verifyPasswordResetCode(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const passwordResetSlice = createSlice({
  name: 'passwordReset',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetStatusBools(state) {
      state.isRequestFulfilled = false
      state.isRequestSubmitted = false
      state.isVerificationFulfilled = false
      state.isVerifying = false
      state.isPasswordUpdated = false
    },
    resetPasswordResetCode(state) {
      state.passwordResetCode = ''
    },
    setPasswordResetCode(state, action) {
      state.passwordResetCode = action.payload
    },
    resetIsVerificationFulfilled(state) {
      state.isVerificationFulfilled = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(sendPasswordResetEmailAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRequestSubmitted = true
        state.isRejected = false
      })
      .addCase(sendPasswordResetEmailAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(sendPasswordResetEmailAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRequestFulfilled = true
      })
      .addCase(verifyPasswordResetCodeAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRequestSubmitted = true
        state.isVerifying = true
        state.isRejected = false
      })
      .addCase(verifyPasswordResetCodeAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(verifyPasswordResetCodeAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRequestFulfilled = true
        state.isVerificationFulfilled = parseInt(action.payload.result) === 1
        state.isVerifying = false
      })
      .addCase(performPasswordResetAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRequestSubmitted = true
        state.isRejected = false
      })
      .addCase(performPasswordResetAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(performPasswordResetAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isPasswordUpdated = true
      })
  },
})

export const getIsLoading = (state) => state.passwordReset.status !== REDUX_SLICE_STATUS_IDLE
export const {
  resetStatusBools,
  resetPasswordResetCode,
  resetIsVerificationFulfilled,
  setPasswordResetCode,
} = passwordResetSlice.actions
export const getIsRequestSubmitted = (state) =>
  state.passwordReset.isRequestSubmitted
export const getIsRequestFulfilled = (state) =>
  state.passwordReset.isRequestFulfilled
export const getPasswordResetCode = (state) =>
  state.passwordReset.passwordResetCode
export const getIsVerificationFulfilled = (state) =>
  state.passwordReset.isVerificationFulfilled
export const getIsVerifying = (state) => state.passwordReset.isVerifying
export const getIsPasswordUpdated = (state) =>
  state.passwordReset.isPasswordUpdated
export default passwordResetSlice.reducer
