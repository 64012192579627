import { useSelector } from 'react-redux'
import { getUserInfo } from '../../../../../features/login/loginSlice'
import { Button } from '../../../../UI/Button/Button'
import { Card } from '../../../../UI/Card/Card'
import { getIsEndingPumpLend, getIsLoading } from '../../../../../features/pump/PumpSlice'

export const Lendedpump = (props) => {
  const userInfo = useSelector(getUserInfo)
  const isEndingPumpLend = useSelector(getIsEndingPumpLend)
  const isLoading = useSelector(getIsLoading)
  return (
    <Card>
      <span>Pump: {props.sparePumpName}</span>
      <span>Lånas av: {props.lendedBy}</span>
      <span>Lånets datum: {props.lendStart}</span>
      <span>Felbeskrivning: {props.faultDescription}</span>
      <span>
        {props.lendEnd !== null ? (
          `Lånet avslutades den: ${props.lendEnd}`
        ) : userInfo.users_id === props.lendedById ? (
          <Button
            clicked={(event) =>
              props.lendEndButtonClick(
                event,
                props.pumpLendId,
                props.sparePumpId,
              )
            }
            isLoading={isEndingPumpLend && isLoading}
          >
            Avsluta lån
          </Button>
        ) : (
          `Ej slutlånad`
        )}
      </span>
    </Card>
  )
}
