import { configureStore } from '@reduxjs/toolkit'
import documentsSlice from '../features/documents/DocumentsSlice'
import waterCheckSlice from '../features/watercheck/WaterCheckSlice'
import loginSlice from '../features/login/loginSlice'
import pagesSlice from '../features/pages/pagesSlice'
import pumpSlice from '../features/pump/PumpSlice'
import registerSlice from '../features/register/registerSlice'
import approveUsersSlice from '../features/approveUsers/approveUsersSlice'
import settingsSlice from '../features/settings/settingsSlice'
import electricityBoxSlice from '../features/electricityBox/electricityBoxSlice'
import passwordResetSlice from '../features/passwordReset/passwordResetSlice'

export const store = configureStore({
  reducer: {
    pages: pagesSlice,
    login: loginSlice,
    register: registerSlice,
    documents: documentsSlice,
    pump: pumpSlice,
    electricityBox: electricityBoxSlice,
    waterChecks: waterCheckSlice,
    approveuser: approveUsersSlice,
    settings: settingsSlice,
    passwordReset: passwordResetSlice,
  },
})
