import { Navigation } from '../../UI/Navigation/Navigation'
import classes from './Layout.module.css'
import MenuButton from '../../UI/Navigation/MenuButton/MenuButton'
import { useState } from 'react'

export const Layout = (props) => {
  const [isMenuShown, setIsMenuShown] = useState(false)
  return (
    <div className={classes.Layout}>
      <MenuButton clicked={() => setIsMenuShown((prev) => !prev)} />
      <Navigation
        isMenuOpen={isMenuShown}
        closeMenu={() => setIsMenuShown((prev) => false)}
      />
      <section className={classes.MainContent}>{props.children}</section>
    </div>
  )
}
