import { domain } from '../apiConfig/apiConfig'

export const fetchDocuments = async () => {
  const result = await fetch(`${domain}documents/getall`)
  return result.json()
}
export const uploadDocument = async (data) => {
  const fileMetaData = new FormData()
  fileMetaData.append('document_file', data.fileContents)
  fileMetaData.append('document_doc_type', data.fileCategory)

  const result = await fetch(`${domain}documents/create`, {
    method: 'post',
    body: fileMetaData,
  })
  return result.json()
}

export const deleteDocument = async (documentId) => {
  const result = await fetch(`${domain}documents/delete/${documentId}`, {
    method: 'delete',
  })
  return result.json()
}

export const changeCategory = async (documentId, newCategory) => {
  const dataToApi = {
    new_category: newCategory,
  }
  const result = await fetch(`${domain}documents/editcategory/${documentId}`, {
    method: 'put',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}
