import classes from './Navigation.module.css'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  getIsLoggedIn,
  getUserInfo,
  setLogout,
} from '../../../features/login/loginSlice'
import { Button } from '../Button/Button'

export const Navigation = (props) => {
  const dispatch = useDispatch()
  let attachedClasses = [classes.Navigation, classes.close]

  if (props.isMenuOpen) {
    attachedClasses = [classes.Navigation, classes.open]
  }
  const isLoggedIn = useSelector(getIsLoggedIn)
  const userInfo = useSelector(getUserInfo)
  const logout = () => {
    dispatch(setLogout())
    props.closeMenu()
  }
  const [menuItems, setMenuItems] = useState(
    <ul>
      <li>
        <NavLink
          to="/"
          className={({ isActive }) => (isActive ? classes.Active : '')}
          onClick={props.closeMenu}
        >
          Hem
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/Login"
          className={({ isActive }) => (isActive ? classes.Active : '')}
          onClick={props.closeMenu}
        >
          Logga in
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/Register"
          className={({ isActive }) => (isActive ? classes.Active : '')}
          onClick={props.closeMenu}
        >
          Registrera Dig
        </NavLink>
      </li>
    </ul>,
  )

  useEffect(() => {
    if (!isLoggedIn) {
      setMenuItems(
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Hem
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/DocumentsView"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Dokument
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Login"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Logga in
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Register"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Registrera Dig
            </NavLink>
          </li>
        </ul>,
      )
    } else {
      setMenuItems(
        <ul>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Hem
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Login/Pumplend"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Pumplån
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/Login/ElectricityBox"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Lån Elektronikbox
            </NavLink>
          </li>
          <li>
            {userInfo.users_priviledge == 'admin' ? (
              <NavLink
                to="/Login/Documents"
                className={({ isActive }) => (isActive ? classes.Active : '')}
                onClick={props.closeMenu}
              >
                Dokument
              </NavLink>
            ) : (
              <NavLink
                to="/DocumentsView"
                className={({ isActive }) => (isActive ? classes.Active : '')}
                onClick={props.closeMenu}
              >
                Dokument
              </NavLink>
            )}
          </li>
          <li>
            <NavLink
              to="/Login/WaterCheck"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Vattenavläsning
            </NavLink>
          </li>
          {userInfo.users_priviledge !== 'normal' && [
            <li key="1">
              <NavLink
                to="/Login/Appropve"
                className={({ isActive }) => (isActive ? classes.Active : '')}
                onClick={props.closeMenu}
              >
                Godkänn användare
              </NavLink>
            </li>,
            <li key="2">
              <NavLink
                to="/Login/EditPages"
                className={({ isActive }) => (isActive ? classes.Active : '')}
                onClick={props.closeMenu}
              >
                Redigera sidinnehåll
              </NavLink>
            </li>,
          ]}
          <li>
            <NavLink
              to="/Login/Settings"
              className={({ isActive }) => (isActive ? classes.Active : '')}
              onClick={props.closeMenu}
            >
              Inställningar
            </NavLink>
          </li>
          {isLoggedIn && (
            <li>
              <Button
                clicked={(event) => {
                  logout(event)
                }}
              >
                Logga ut
              </Button>
            </li>
          )}
        </ul>,
      )
    }
  }, [isLoggedIn, userInfo.users_priviledge])

  return <nav className={attachedClasses.join(' ')}>{menuItems}</nav>
}
