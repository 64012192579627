import { domain } from '../apiConfig/apiConfig'

export const createPumpLend = async (pumpLend) => {
  const dataToApi = {
    lends_start: pumpLend.lendStart,
    lends_lended_by: pumpLend.lendedBy,
    lends_lended_pump: pumpLend.lendedPump,
    lends_description: pumpLend.faultDescription,
    session_id: pumpLend.sessionId,
  }
  const result = await fetch(`${domain}pump/createlends`, {
    method: 'post',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}

export const createElectricityBoxLend = async (pumpLend) => {
  const dataToApi = {
    lends_start: pumpLend.lendStart,
    lends_lended_by: pumpLend.lendedBy,
    session_id: pumpLend.sessionId,
  }
  const result = await fetch(`${domain}pump/createelectricityboxlends`, {
    method: 'post',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}
export const getAllPumpLends = async () => {
  const result = await fetch(`${domain}pump/lends`)
  return result.json()
}

export const getAllElectricityBoxLends = async () => {
  const result = await fetch(`${domain}pump/getelectricityboxlends`)
  return result.json()
}
export const getAllPumps = async () => {
  const result = await fetch(`${domain}sparepump/getall`)
  return result.json()
}
export const endPumpLend = async (data) => {
  const dataToApi = {
    pump_lends_id: data.pumpLendId,
    session_id: data.sessionId,
    lends_end: data.dateOfLendEnd,
    spare_pumps_id: data.sparePumpId,
  }

  const result = await fetch(`${domain}pump/pumplenddone/${data.pumpLendId}`, {
    method: 'put',
    body: JSON.stringify(dataToApi),
  })
  return result.json()
}

export const endElectricityBoxLend = async (data) => {
  const dataToApi = {
    el_box_lends_id: data.pumpLendId,
    session_id: data.sessionId,
    lends_end: data.dateOfLendEnd,
  }

  const result = await fetch(
    `${domain}pump/endelectricityboxlend/${data.pumpLendId}`,
    {
      method: 'put',
      body: JSON.stringify(dataToApi),
    },
  )
  return result.json()
}
