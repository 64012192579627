import React from 'react'
import './App.css'
import { Routes, Route } from 'react-router'
import { Home } from './app/components/Home/Home'
import { Login } from './app/components/Login/Login'
import { Register } from './app/components/Register/Register'
import { Layout } from './app/components/Layout/Layout'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import { ResetPassword } from './app/components/Login/ResetPassword/ResetPassword'
import { AskForNewPwd } from './app/components/Login/AskForNewPwd/AskForNewPwd'
import { Documentsview } from './app/components/Documentsview/Documentsview'

function App() {
  return (
    <Layout>
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/DocumentsView" element={<Documentsview />} />
        <Route path="/login/*" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/askfornewpwd" element={<AskForNewPwd />} />
        <Route
          path="/resetPassword/:passwordResetCode"
          element={<ResetPassword />}
        />
      </Routes>
    </Layout>
  )
}

export default App
