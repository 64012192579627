import { domain } from '../apiConfig/apiConfig'

export const loginRequest = async ({ username, password }) => {
  const loginData = JSON.stringify({
    user_username: username,
    user_password: password,
  })
  const result = await fetch(`${domain}login/login`, {
    method: 'post',
    body: loginData,
  })
  return result.json()
}

export const getUserInfoRequest = async (sessionId) => {
  const result = await fetch(`${domain}login/getinfo/${sessionId}`)
  return result.json()
}
export const checkLoginStatus = async (sessionId) => {
  const result = await fetch(`${domain}login/get/${sessionId}`)
  return result.json()
}
