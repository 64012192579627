import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit'
import {
  REDUX_SLICE_STATUS_IDLE,
  REDUX_SLICE_STATUS_LOADING,
} from '../constantsConfig/constantsConfig'
import {
  createPumpLend,
  endPumpLend,
  getAllPumpLends,
  getAllPumps,
} from './PumpApi'

const initialState = {
  pumpLends: [],
  sparePumps: [],
  status: REDUX_SLICE_STATUS_IDLE,
  createPumpLendSuccess: false,
  endPumpLendSuccess: false,
  pumpsFreeToLend: [],
  isPumpsFreeToLendInitiated: false,
  isRejected: false,
  isEndingPumpLend: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const createPumpLendAsync = createAsyncThunk(
  'pumpLends/create',
  async (data) => {
    const response = await createPumpLend(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const getSparePumpsAsync = createAsyncThunk(
  'pumpLends/getSparePumps',
  async () => {
    const response = await getAllPumps()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const getPumpLendsAsync = createAsyncThunk(
  'pumpLends/getAll',
  async () => {
    const response = await getAllPumpLends()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const endPumpLendAsync = createAsyncThunk(
  'pumpLends/endPumpLend',
  async (data) => {
    const response = await endPumpLend(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const pumpSlice = createSlice({
  name: 'pump',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetPumpLendSuccess(state) {
      state.createPumpLendSuccess = false
    },
    resetEndPumpLendSuccess(state) {
      state.endPumpLendSuccess = false
    },
    resetPumpLends(state) {
      state.pumpLends = []
    },
    resetIsPumpsFreeToLendInitiated(state) {
      state.isPumpsFreeToLendInitiated = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(createPumpLendAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(createPumpLendAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(createPumpLendAsync.fulfilled, (state, action) => {
        const sparePumpToRemove = state.sparePumps.find(
          (p) => p.spare_pumps_id == action.payload.spare_pumps_id,
        )
        state.pumpsFreeToLend = state.pumpsFreeToLend.filter(
          (p) => p.spare_pumps_id != sparePumpToRemove.spare_pumps_id,
        )
        state.status = REDUX_SLICE_STATUS_IDLE
        state.createPumpLendSuccess = true
      })
      .addCase(getPumpLendsAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isPumpsFreeToLendInitiated = false
        state.isRejected = false
      })
      .addCase(getPumpLendsAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isPumpsFreeToLendInitiated = false
        state.isRejected = true
      })
      .addCase(getPumpLendsAsync.fulfilled, (state, action) => {
        state.pumpLends = action.payload
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = false
        if (!state.isPumpsFreeToLendInitiated) {
          let idsLended = state.pumpLends
            .filter((pfl) => pfl.information.pump_lends_end == null)
            .map((p) => parseInt(p.spare_pumps_id))
            .filter(
              (obj1, i, array1) =>
                array1.findIndex((obj2, i) => obj2 === obj1) == i,
            )
          if (
            idsLended.length === state.sparePumps.length
          ) {
            state.pumpsFreeToLend = []
          } else if (
            idsLended.length > 1
          ) {
            state.pumpsFreeToLend = state.sparePumps.filter(
              (sp) =>
                idsLended.filter((id) => id == sp.spare_pumps_id)[0] !=
                sp.spare_pumps_id,
            )
          } else if (
            idsLended.length == 1
          ) {
            state.pumpsFreeToLend = state.sparePumps.filter(
              (sp) => sp.spare_pumps_id != idsLended[0],
            )
          } else {
            state.pumpsFreeToLend = state.sparePumps
          }
          state.isPumpsFreeToLendInitiated = true
        }
      })
      .addCase(getSparePumpsAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getSparePumpsAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getSparePumpsAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = false
        state.sparePumps = action.payload.map((p) => {
          return {
            spare_pumps_id: parseInt(p.spare_pumps_id),
            spare_pumps_name: p.spare_pumps_name,
          }
        })
      })
      .addCase(endPumpLendAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
        state.isEndingPumpLend = true
      })
      .addCase(endPumpLendAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
        state.isEndingPumpLend = true
      })
      .addCase(endPumpLendAsync.fulfilled, (state, action) => {
        state.pumpsFreeToLend = [
          ...state.pumpsFreeToLend,
          state.sparePumps.find(
            (sp) => sp.spare_pumps_id == action.payload.spare_pumps_id,
          ),
        ]
        state.pumpsFreeToLend.sort(
          (a, b) => a.spare_pumps_id - b.spare_pumps_id,
        )
        state.status = REDUX_SLICE_STATUS_IDLE
        state.endPumpLendSuccess = true
        state.isEndingPumpLend = false
      })
  },
})

export const {
  resetPumpLendSuccess,
  resetPumpLends,
  resetEndPumpLendSuccess,
  populateInitialSparePumpsFreeToLend,
  resetElectricityBoxLendSuccess,
  resetElectricityBoxLends,
  resetEndElecrtricityBoxLendSuccess,
  resetIsPumpsFreeToLendInitiated,
} = pumpSlice.actions
export const getPumpLends = (state) => state.pump.pumpLends
export const getSparePumps = (state) => state.pump.sparePumps
export const getCreatePumpLendSuccess = (state) =>
  state.pump.createPumpLendSuccess
export const getEndPumpLendSuccess = (state) => state.pump.endPumpLendSuccess
export const getIsLoading = (state) =>
  state.pump.status != REDUX_SLICE_STATUS_IDLE
export const getPumpsFreeToLend = (state) => state.pump.pumpsFreeToLend
export const getElectricityBoxLends = (state) => state.pump.electricityBoxLends
export const getCreateElectricityBoxLendSuccess = (state) =>
  state.pump.createElectricityBoxLendSuccess
export const getEndElectricityBoxLendSuccess = (state) =>
  state.pump.endElectricityBoxLendSuccess
export const getIsPumpsFreeToLendInitiated = (state) =>
  state.pump.isPumpsFreeToLendInitiated
export const getIsEndingPumpLend = (state) => state.pump.isEndingPumpLend
export default pumpSlice.reducer
