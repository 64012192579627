import classes from './Button.module.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import '@fortawesome/fontawesome-svg-core/styles.css'

export const Button = (props) => {
  return (
    <>
      <button
        className={classes.Button}
        onClick={props.clicked}
        disabled={props.disabled == true}
      >
        {props.children}

        {props.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      </button>
    </>
  )
}
