import classes from './Home.module.css'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { getPageAsync, pageInfo } from '../../../features/pages/pagesSlice'
import parse from 'html-react-parser'
export const Home = () => {
  const dispatch = useDispatch()
  const fetchedPageInfo = useSelector(pageInfo)
  const pageContent = fetchedPageInfo ? fetchedPageInfo.pages_text : ''
  useEffect(() => {
    if (fetchedPageInfo == null) {
      const fetchData = () => {
        dispatch(getPageAsync(1))
      }
      fetchData()
    }
  })
  return parse(pageContent)
}
