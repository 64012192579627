import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import { updateEmailRequest, updatePasswordRequest } from './settingsApi'

const initialState = {
  status: REDUX_SLICE_STATUS_IDLE,
  isSuccessfull: false,
  isRequestSent: false,
  isRejected: true
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const updatePasswordAsync = createAsyncThunk(
  'settings/updateUserPassword',
  async (credentials) => {
    console.log('updatePasswordAsync', credentials)
    const response = await updatePasswordRequest({
      userPassword: credentials.userPassword,
      userId: credentials.userId,
      sessionId: credentials.sessionId,
    })
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const updateEmailAsync = createAsyncThunk(
  'settings/updateUserEmail',
  async (credentials) => {
    console.log('updateEmailAsync', {
      userEmail: credentials.userEmail,
      userId: credentials.userId,
      sessionId: credentials.sessionId,
    })
    const response = await updateEmailRequest({
      userEmail: credentials.userEmail,
      userId: credentials.userId,
      sessionId: credentials.sessionId,
    })
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSentState(state) {
      state.isSuccessfull = false
      state.isRequestSent = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(updatePasswordAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(updatePasswordAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(updatePasswordAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isSuccessfull = action.payload[0] === 1
        state.isRequestSent = true
        console.log(state.isRequestSent, state.isRequestSent)
      })
      .addCase(updateEmailAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(updateEmailAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(updateEmailAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isSuccessfull = action.payload[0] === 1
        state.isRequestSent = true
      })
  },
})

export const getStatus = (state) => state.settings.status
export const getSuccessfull = (state) => state.settings.isSuccessfull
export const getRequestSent = (state) => state.settings.isRequestSent
export const { resetSentState } = settingsSlice.actions

export default settingsSlice.reducer
