import { useEffect, useRef } from 'react'
import { Button } from '../Button/Button'
import { Input } from '../Form/Input/Input'
import classes from './Form.module.css'

export const Form = (props) => {
  const formDefinition = props.formDefinition
  const formRef = useRef(null)

  useEffect(() => {
    if (props.reset) {
      formRef.current.reset()
    }
  }, [props.reset])

  useEffect(() => {
  }, [props.inputErrors])
  const formElements = formDefinition.map((x, i) => {
    return (
      <Input
        key={`${i}-${x.type}`}
        type={x.type}
        change={
          typeof x.editorHandler !== 'undefined'
            ? (data) => x.editorHandler(data)
            : (event) => x.handler(event)
        }
        labelText={x.labelText}
        selectOptions={x.selectOptions}
        allowedFileType={x.allowedFileType}
        text={x.text}
        placeHolder={x.placeHolder}
        disabled={props.disabled}
        value={props.value}
        fieldName={x.fieldName}
        inputErrors={props.inputErrors}
      />
    )
  })
  return (
    <div className={classes.Form}>
      <form ref={formRef}>
        {formElements}
        <Button
          clicked={props.clicked}
          isLoading={props.isLoading}
          disabled={props.disabled}
        >
          {props.buttonText}
        </Button>
      </form>
    </div>
  )
}
