import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import { checkLoginStatus, getUserInfoRequest, loginRequest } from './loginApi'

const initialState = {
  sessionId: '',
  status: REDUX_SLICE_STATUS_IDLE,
  loginRequestMade: false,
  userInfo: {
    users_id: '',
    users_username: '',
    users_priviledge: '',
    users_email: '',
    rejected: false
  },
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const loginAsync = createAsyncThunk(
  'login/login',
  async (credentials) => {
    const response = await loginRequest({
      username: credentials.username,
      password: credentials.password,
    })
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const getUserInfoAsync = createAsyncThunk(
  'login/getUserInfo',
  async (sessionId) => {
    const response = await getUserInfoRequest(sessionId)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const checkLoginStatusAsync = createAsyncThunk(
  'login/checkLoginStatus',
  async (sessionId) => {
    if (sessionId === '') {
      sessionId = localStorage.getItem('sessionIdTarnoNu')
    }
    const response = await checkLoginStatus(sessionId)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const loginSlice = createSlice({
  name: 'login',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setLogout(state) {
      state.sessionId = ''
      localStorage.removeItem('sessionIdTarnoNu')
    },
    assignSessionIdIfAny(state) {
      if (state.sessionId === '') {
        if (typeof localStorage.getItem('sessionIdTarnoNu') !== 'undefined') {
          state.sessionId = localStorage.getItem('sessionIdTarnoNu')
        }
      }
    },
    resetLoginRequestMade(state) {
      state.loginRequestMade = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.sessionId = action.payload.session_id
        localStorage.setItem('sessionIdTarnoNu', state.sessionId)
        state.loginRequestMade = true
      })
      .addCase(getUserInfoAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getUserInfoAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = true
      })
      .addCase(getUserInfoAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        if (action.payload === -1) {
          state.sessionId = ''
          localStorage.removeItem('sessionIdTarnoNu')
        } else {
          state.userInfo.users_id = action.payload.users_id
          state.userInfo.users_username = action.payload.users_username
          state.userInfo.users_priviledge = action.payload.users_priviledge
          state.userInfo.users_email = action.payload.users_email
            ? action.payload.users_email
            : ''
        }
      })
      .addCase(checkLoginStatusAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(checkLoginStatusAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(checkLoginStatusAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        if (action.payload === false) {
          state.sessionId = ''
          localStorage.removeItem('sessionIdTarnoNu')
        }
      })
  },
})

export const getSessionId = (state) => {
  return state.login.sessionId
}
export const getIsLoggedIn = (state) =>
  state.login.sessionId !== '' &&
  localStorage.getItem('sessionIdTarnoNu') !== null
export const {
  setLogout,
  assignSessionIdIfAny,
  resetLoginRequestMade,
} = loginSlice.actions
export const getUserInfo = (state) => state.login.userInfo
export const hasLoginRequestBeenMade = (state) => state.login.loginRequestMade
export const getIsLoading = (state) => state.login.status !== REDUX_SLICE_STATUS_IDLE
export default loginSlice.reducer
