import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  getIsLoading,
  getElectricityBoxLends,
  getCreateElectricityBoxLendSuccess,
  getEndElectricityBoxLendSuccess,
  resetEndElecrtricityBoxLendSuccess,
  resetElectricityBoxLends,
  getElectricityBoxLendsAsync,
  resetElectricityBoxLendSuccess,
  createElectricityBoxLendAsync,
  endElectricityBoxLendAsync,
  getElectricityBoxesFreeToLend,
  getElectricityBoxesAsync,
  getEelBoxLendsFetched,
  getIsEndingElectricityBoxLend,
  getIsElectricityBoxesFreeToLendInitiated,
} from '../../../../features/electricityBox/electricityBoxSlice'
import { getSessionId } from '../../../../features/login/loginSlice'
import { Form } from '../../../UI/Form/Form'
import { LendedElectricityBox } from './LendedElectricityBox/LendedElectricityBox'
import classes from './ElectricityBoxLend.module.css'
import { toast } from 'react-toastify'
import { toastifyConfig } from '../../../../features/toastifyConfig/toastifyConfig'

export const ElectricityBoxLend = () => {
  const dispatch = useDispatch()
  const sessionId = useSelector(getSessionId)
  const electricityBoxLends = useSelector(getElectricityBoxLends)
  const [formData, setFormData] = useState({
    lendedElBoxId: 1,
    lendedBy: '',
    lendStart: '',
    estate: '',
  })
  const success = useSelector(getCreateElectricityBoxLendSuccess)
  const successElectricityBoxEnd = useSelector(getEndElectricityBoxLendSuccess)
  const isLoading = useSelector(getIsLoading)
  const isEndingElectricityBoxLend = useSelector(getIsEndingElectricityBoxLend)

  const electricityBoxesFreeToLend = useSelector(getElectricityBoxesFreeToLend)

  const isElectricityBoxesFreeToLendInitiated = useSelector(getIsElectricityBoxesFreeToLendInitiated)
  const [errorMessage, setErrorMessage] = useState('')
  const [resetForm, setResetForm] = useState(false)

  const formDefinition = [
    { type: 'label', labelText: 'Lånas av' },
    {
      type: 'text',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendedBy: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Fastighet' },
    {
      type: 'text',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, estate: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'När påbörjades lånet' },
    {
      type: 'date',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendStart: event.target.value }
        })
      },
    },
    { type: 'label', labelText: 'Elektronikbox att låna' },
    {
      type: 'select',
      handler: (event) => {
        setFormData((prev) => {
          return { ...prev, lendedElBoxId: event.target.value }
        })
      },
      selectOptions: electricityBoxesFreeToLend.map((electricityBox) => {
        return {
          optionValue: electricityBox.el_boxes_id,
          optionText: electricityBox.el_boxes_name,
        }
      }),
    },
  ]

  const allDataSet = () => {
    let isValid = true
    if (formData.lendedBy === '') {
      isValid = false
    }
    if (formData.lendStart === '') {
      isValid = false
    }
    if (formData.estate === '') {
      isValid = false
    }
    return isValid
  }

  const buttonClickedHandler = (event) => {
    event.preventDefault()
    setErrorMessage('')
    setResetForm(false)
    dispatch(resetElectricityBoxLendSuccess())
    if (!allDataSet()) {
      setErrorMessage('Kontrollera att formuläret är korrekt ifyllt.')
      return
    }

    dispatch(createElectricityBoxLendAsync(formData))
    setFormData({
      lendedElBoxId: 1,
      lendedBy: '',
      lendStart: '',
      estate: '',
    })
  }

  const buttonEndLendHandler = (
    event,
    electricityBoxLendId,
    electricityBoxId,
  ) => {
    event.preventDefault()
    const dateOfLendEnd = new Date()
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ')

    dispatch(resetEndElecrtricityBoxLendSuccess())
    dispatch(
      endElectricityBoxLendAsync({
        dateOfLendEnd,
        sessionId,
        electricityBoxId,
        electricityBoxLendId,
      }),
    )
  }

  useEffect(() => {
    dispatch(getElectricityBoxesAsync())
    dispatch(getElectricityBoxLendsAsync())
  }, [])

  useEffect(() => {
    setFormData((prev) => {
      return { ...prev, sessionId }
    })
  }, [sessionId])

  useEffect(() => {
    if (success) {
      toast.success('Elboxlånet sparades', toastifyConfig)
      dispatch(resetElectricityBoxLends())
      dispatch(getElectricityBoxLendsAsync())
      setResetForm(true)
    } else if (successElectricityBoxEnd) {
      dispatch(resetElectricityBoxLends())
      dispatch(getElectricityBoxLendsAsync())
    }
    return () => {
      dispatch(resetEndElecrtricityBoxLendSuccess())
      dispatch(resetElectricityBoxLendSuccess())
    }
  }, [success, successElectricityBoxEnd, electricityBoxesFreeToLend])

  useEffect(() => {}, [resetForm])

  useEffect(() => {
    errorMessage.length !== 0 && toast.error(errorMessage, toastifyConfig)
  }, [errorMessage])

  useEffect(() => {
    if (isElectricityBoxesFreeToLendInitiated && electricityBoxesFreeToLend.length === 1) {
      setFormData((prev) => {
        return { ...prev, lendedElBoxId: electricityBoxesFreeToLend[0].el_boxes_id }
      })
    }
  }, [isElectricityBoxesFreeToLendInitiated])
  return (
    <div className={classes.PumpLend}>
      <div>
        <p>
          Här kan du registrera att du har hämtat och ersatt en elektronikbox.
          <br />
          <br />
          <strong style={{ color: 'red' }}>OBS: </strong>
          <strong>
            Den ”lånade” boxen skall ersättas med en ny. Denna införskaffas av
            medlemmen själv via Skandinavisk Kommunalteknik
            <a href="https://kommunalteknik.se"> https://kommunalteknik.se</a>.
          </strong>
        </p>
        {isElectricityBoxesFreeToLendInitiated && electricityBoxesFreeToLend.length == 0 && (
          <p className={classes.Error}>
            Alla elektronikboxar är utlånade, kom tillbaka senare
          </p>
        )}
        <Form
          disabled={electricityBoxesFreeToLend.length == 0}
          formDefinition={formDefinition}
          buttonText="Låna elektronikbox"
          clicked={(event) => {
            buttonClickedHandler(event)
          }}
          isLoading={!isEndingElectricityBoxLend && isLoading}
          reset={resetForm}
        ></Form>
      </div>
      <div>
        <h1>Registrerade lån av Elektronikbox</h1>
        {electricityBoxLends.length === 0 && (
          <p>Inga lån av elektronikboxar finns just nu.</p>
        )}
        {electricityBoxLends.map((ebl) => {
          return (
            <LendedElectricityBox
              key={ebl.el_box_lends_id}
              electricityBoxId={ebl.el_box_lended_el_box_id}
              electricityBoxName={ebl.el_boxes_name}
              electricityBoxLendsId={ebl.el_box_lends_id}
              electricityBoxLendsEstate={ebl.el_box_lends_estate}
              lendedBy={ebl.el_box_lends_lended_by}
              lendedById={ebl.el_box_lends_users_id}
              lendStart={ebl.el_box_lends_start}
              lendEnd={ebl.el_box_lends_end}
              lendEndButtonClick={buttonEndLendHandler}
            />
          )
        })}
      </div>
    </div>
  )
}
