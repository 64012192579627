import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
  REDUX_SLICE_STATUS_IDLE,
  REDUX_SLICE_STATUS_LOADING,
} from '../constantsConfig/constantsConfig'
import { createWaterCheck, getAllWaterChecks } from './WaterCheckApi'

const initialState = {
  waterChecks: [],
  status: REDUX_SLICE_STATUS_IDLE,
  waterSuccess: false,
  waterCSV: false,
  isRejected: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const createWaterCheckAsync = createAsyncThunk(
  'WaterCheck/create',
  async (data) => {
    const response = await createWaterCheck(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const getWaterChecksAsync = createAsyncThunk(
  'waterCheck/get',
  async () => {
    const response = await getAllWaterChecks()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const waterCheckSlice = createSlice({
  name: 'waterCheck',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSuccess(state) {
      state.waterSuccess = false
      state.waterCSV = false
    },
    resetWaterCheckList(state) {
      state.waterChecks = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getWaterChecksAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getWaterChecksAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getWaterChecksAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        if (!state.isRejected) {
          state.waterChecks = action.payload
        }
      })
      .addCase(createWaterCheckAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
      })
      .addCase(createWaterCheckAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(createWaterCheckAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.waterSuccess = true
      })
  },
})

export const { resetSuccess, resetWaterCheckList } = waterCheckSlice.actions
export const getWaterChecks = (state) => state.waterChecks.waterChecks
export const getWaterSuccess = (state) => state.waterChecks.waterSuccess
export const getIsLoading = (state) =>
  state.waterChecks.status != REDUX_SLICE_STATUS_IDLE
export const waterCSV = (state) => state.waterCheckSlice.waterCSV

export default waterCheckSlice.reducer
