import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import { editPage, fetchPageById, fetchPages } from './pagesApi'

const initialState = {
  page: null,
  pages: [],
  status: REDUX_SLICE_STATUS_IDLE,
  editPageRequestSent: false,
  editPageSuccess: false,
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getPageAsync = createAsyncThunk(
  'pages/getPage',
  async (pageId) => {
    const response = await fetchPageById(pageId)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const getPagesAsync = createAsyncThunk('pages/getPages', async () => {
  const response = await fetchPages()
  // The value we return becomes the `fulfilled` action payload
  return response
})

export const editPageAsync = createAsyncThunk(
  'pages/editPage',
  async (editPageData) => {
    const response = await editPage(editPageData)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const pagesSlice = createSlice({
  name: 'pages',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetEditPageSuccess(state) {
      state.editPageSuccess = false
      state.editPageRequestSent = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getPageAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getPageAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getPageAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.page = action.payload
      })
      .addCase(getPagesAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getPagesAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getPagesAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.pages = action.payload
      })
      .addCase(editPageAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(editPageAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(editPageAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.editPageSuccess = action.payload === 1
        state.editPageRequestSent = true
      })
  },
})

export const pageInfo = (state) => {
  return state.pages.page
}
export const pages = (state) => {
  return state.pages.pages
}

export const editPageSuccess = (state) => {
  return state.pages.editPageSuccess
}
export const editPageRequestSent = (state) => {
  return state.pages.editPageRequestSent
}

export const getIsLoading = (state) => state.pages.status != REDUX_SLICE_STATUS_IDLE

export const { resetEditPageSuccess } = pagesSlice.actions
export default pagesSlice.reducer
