import { useSelector } from 'react-redux'
import { getUserInfo } from '../../../../../features/login/loginSlice'
import { Button } from '../../../../UI/Button/Button'
import { Card } from '../../../../UI/Card/Card'
import { getIsEndingElectricityBoxLend, getIsLoading } from '../../../../../features/electricityBox/electricityBoxSlice'

export const LendedElectricityBox = (props) => {
  const userInfo = useSelector(getUserInfo)
  const isEndingElectricityBoxLend = useSelector(getIsEndingElectricityBoxLend)
  const isLoading = useSelector(getIsLoading)
  return (
    <Card>
      <span>Elektronikbox: {props.electricityBoxName}</span>
      <span>Lånas av: {props.lendedBy}</span>
      <span>Fastighet: {props.electricityBoxLendsEstate}</span>
      <span>Lånets datum: {props.lendStart}</span>
      <span>
        {props.lendEnd !== null ? (
          `Återlämnad den: ${props.lendEnd}`
        ) : userInfo.users_id === props.lendedById ? (
          <Button
            clicked={(event) =>
              props.lendEndButtonClick(
                event,
                props.electricityBoxLendsId,
                props.electricityBoxId,
              )
            }
            isLoading={isEndingElectricityBoxLend && isLoading}
          >
            Återlämna
          </Button>
        ) : (
          `Ej återlämnad`
        )}
      </span>
    </Card>
  )
}
