import classes from './Uploadeddocument.module.css'
import { domain } from '../../../../../features/apiConfig/apiConfig'
import { Button } from '../../../../UI/Button/Button'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCategoryChangedRequestSent,
  getCategoryChangedSuccessfully,
  getCategoryList,
  getShowSearchActiveFor,
  resetCategoryChanged,
  setShowSearchActiveFor,
} from '../../../../../features/documents/DocumentsSlice'
import { useEffect, useState } from 'react'

export const Uploadeddocument = (props) => {
  const [newCategory, setNewCategory] = useState()
  const dispatch = useDispatch()
  const showSearchActiveFor = useSelector(getShowSearchActiveFor)
  const categoryList = useSelector(getCategoryList)

  const categoryChangedSuccessfully = useSelector(
    getCategoryChangedSuccessfully,
  )
  const categoryChangedRequestSent = useSelector(getCategoryChangedRequestSent)

  const setEditMode = () => {
    dispatch(setShowSearchActiveFor(props.id))
  }
  const onChangeCategoryHandler = (newCategory) => {
    console.log('On choose category', newCategory)
    setNewCategory(newCategory)
  }
  const [categorySelectClasses, setCategorySelectClasses] = useState([
    classes.NewCategory,
    classes.Close,
  ])
  const [uploadedDocumentClasses, setUploadedDocumentClass] = useState([
    classes.UploadedDocument,
  ])

  useEffect(() => {}, [props.displayExtraColumn])

  useEffect(() => {
    if (showSearchActiveFor == '') {
      setCategorySelectClasses(() => [classes.NewCategory, classes.Close])
      setUploadedDocumentClass(() => [classes.UploadedDocument])
    } else if (showSearchActiveFor == props.id) {
      setCategorySelectClasses((prev) => [classes.NewCategory, classes.Open])
      setUploadedDocumentClass((prev) => [...prev, classes.SearchActive])
    } else if (showSearchActiveFor != props.id) {
      setUploadedDocumentClass((prev) => [...prev, classes.SearchActive])
    }
  }, [showSearchActiveFor, props.id])

  useEffect(() => {
    if (categoryChangedRequestSent && categoryChangedSuccessfully) {
      setEditMode()
      dispatch(resetCategoryChanged())
    }
  }, [categoryChangedRequestSent, categoryChangedSuccessfully])

  useEffect(() => {}, categorySelectClasses, uploadedDocumentClasses)
  return (
    <div className={uploadedDocumentClasses.join(' ')}>
      {props.name}
      <Button clicked={setEditMode}>
        {showSearchActiveFor == props.id ? 'Dölj kategori' : 'Visa kategori'}
      </Button>
      <select
        className={categorySelectClasses.join(' ')}
        onChange={(event) => {
          props.updateCategoryHandler(
            event,
            props.documentId,
            event.target.value,
          )
        }}
      >
        {categoryList.map((c) =>
          props.category === c.value ? (
            <option selected value={c.value}>
              --{c.label}--
            </option>
          ) : (
            <option value={c.value}>{c.label}</option>
          ),
        )}
      </select>
      <a href={`${domain}documents/get/${props.id}`}>
        <Button>Ladda ned</Button>
      </a>
      <Button clicked={(event) => props.removeHandler(event, props.id)}>
        Ta bort
      </Button>
    </div>
  )
}
