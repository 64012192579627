import {
  createAsyncThunk,
  createSlice,
  current,
  isRejected,
} from '@reduxjs/toolkit'
import {
  REDUX_SLICE_STATUS_IDLE,
  REDUX_SLICE_STATUS_LOADING,
} from '../constantsConfig/constantsConfig'
import {
  createElectricityBoxLend,
  endElectricityBoxLend,
  getAllElectricityBoxLends,
  getAllElectricityBoxes,
} from './electricityBoxApi'

const initialState = {
  status: REDUX_SLICE_STATUS_IDLE,
  electricityBoxLends: [],
  createElectricityBoxLendSuccess: false,
  endElectricityBoxLendSuccess: false,
  electricityBoxesFreeToLend: [],
  electricityBoxes: [],
  isEndingElectricityBoxLend: false,
  isElectricityBoxesFreeToLendInitiated: false,
  isRejected: false,
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getElectricityBoxesAsync = createAsyncThunk(
  'electricityBox/getElectricityBoxes',
  async () => {
    const response = await getAllElectricityBoxes()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const getElectricityBoxLendsAsync = createAsyncThunk(
  'electricityBox/getElectricityBoxLends',
  async () => {
    const response = await getAllElectricityBoxLends()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const createElectricityBoxLendAsync = createAsyncThunk(
  'electricityBox/createElectricityBoxLends',
  async (data) => {
    const response = await createElectricityBoxLend(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)
export const endElectricityBoxLendAsync = createAsyncThunk(
  'electricityBox/endElectricityBoxLend',
  async (data) => {
    const response = await endElectricityBoxLend(data)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const electricityBoxSlice = createSlice({
  name: 'electricityBox',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetElectricityBoxLendSuccess(state) {
      state.createElectricityBoxLendSuccess = false
    },
    resetEndElecrtricityBoxLendSuccess(state) {
      state.endElectricityBoxLendSuccess = false
    },
    resetElectricityBoxLends(state) {
      state.electricityBoxLends = []
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getElectricityBoxesAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getElectricityBoxesAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getElectricityBoxesAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = false
        state.electricityBoxes = action.payload.map((el) => {
          return {
            el_boxes_id: parseInt(el.el_boxes_id),
            el_boxes_name: el.el_boxes_name,
          }
        })
      })
      .addCase(getElectricityBoxLendsAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isElectricityBoxesFreeToLendInitiated = false
        state.isRejected = false
      })
      .addCase(getElectricityBoxLendsAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isElectricityBoxesFreeToLendInitiated = false
        state.isRejected = true
      })
      .addCase(getElectricityBoxLendsAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.electricityBoxLends = action.payload
        state.isRejected = false

        if (!state.isElectricityBoxesFreeToLendInitiated) {
          state.status = REDUX_SLICE_STATUS_IDLE
          let idsLended = state.electricityBoxLends
            .filter((ebfl) => ebfl.el_box_lends_end == null)
            .map((eb) => parseInt(eb.el_box_lends_el_box_id))
            .filter(
              (obj1, i, array1) =>
                array1.findIndex((obj2, i) => obj2 === obj1) == i,
            )
          if (idsLended.length === state.electricityBoxes.length) {
            state.electricityBoxesFreeToLend = []
          } else if (idsLended.length > 1) {
            state.electricityBoxesFreeToLend = state.electricityBoxes.filter(
              (elb) =>
                idsLended.filter((id) => id == elb.el_boxes_id)[0] !=
                elb.el_boxes_id,
            )
          } else if (idsLended.length == 1) {
            state.electricityBoxesFreeToLend = state.electricityBoxes.filter(
              (elb) => elb.el_boxes_id != idsLended[0],
            )
          } else {
            state.electricityBoxesFreeToLend = state.electricityBoxes
          }
          state.isElectricityBoxesFreeToLendInitiated = true
        }
      })
      .addCase(createElectricityBoxLendAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(createElectricityBoxLendAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(createElectricityBoxLendAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = false
        const electricityBoxToRemove = state.electricityBoxes.find(
          (p) => p.el_boxes_id == action.payload.el_boxes_id,
        )
        state.electricityBoxesFreeToLend = state.electricityBoxesFreeToLend.filter(
          (p) => p.el_boxes_id != electricityBoxToRemove.el_boxes_id,
        )
        state.status = REDUX_SLICE_STATUS_IDLE
        state.createElectricityBoxLendSuccess = true
        state.isRejected = false
      })
      .addCase(endElectricityBoxLendAsync.pending, (state) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(endElectricityBoxLendAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
      })
      .addCase(endElectricityBoxLendAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = false
        state.electricityBoxesFreeToLend = [
          ...state.electricityBoxesFreeToLend,
          state.electricityBoxes.find(
            (sp) => sp.el_boxes_id == action.payload.el_boxes_id,
          ),
        ]
        state.electricityBoxesFreeToLend.sort(
          (a, b) => a.el_boxes_id - b.el_boxes_id,
        )
        state.status = REDUX_SLICE_STATUS_IDLE
        state.endElectricityBoxLendSuccess = true
        state.isEndingElectricityBoxLend = false
      })
  },
})

export const {
  resetElectricityBoxLendSuccess,
  resetElectricityBoxLends,
  resetEndElecrtricityBoxLendSuccess,
} = electricityBoxSlice.actions
export const getPumpLends = (state) => state.electricityBox.electricityBoxLends
export const getElectricityBoxes = (state) => state.electricityBox.elBoxes
export const getCreatePumpLendSuccess = (state) =>
  state.electricityBox.createPumpLendSuccess
export const getEndPumpLendSuccess = (state) =>
  state.electricityBox.endPumpLendSuccess
export const getIsLoading = (state) =>
  state.electricityBox.status !== REDUX_SLICE_STATUS_IDLE
export const getElectricityBoxesFreeToLend = (state) =>
  state.electricityBox.electricityBoxesFreeToLend
export const getElectricityBoxLends = (state) =>
  state.electricityBox.electricityBoxLends
export const getCreateElectricityBoxLendSuccess = (state) =>
  state.electricityBox.createElectricityBoxLendSuccess
export const getEndElectricityBoxLendSuccess = (state) =>
  state.electricityBox.endElectricityBoxLendSuccess
export const getIsEndingElectricityBoxLend = (state) =>
  state.electricityBox.isEndingElectricityBoxLend
export const getIsElectricityBoxesFreeToLendInitiated = (state) =>
  state.electricityBox.isElectricityBoxesFreeToLendInitiated
export default electricityBoxSlice.reducer
