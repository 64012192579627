import { domain } from '../apiConfig/apiConfig'

export const registerRequest = async ({
  userFirstname,
  userLastname,
  userName,
  userPassword,
  userEmail,
  userPriviledge,
  recaptchaAnswer,
}) => {
  const registrationData = JSON.stringify({
    user_firstname: userFirstname,
    user_lastname: userLastname,
    user_username: userName,
    user_password: userPassword,
    user_email: userEmail,
    user_priviledge: userPriviledge,
    recaptcha_answer: recaptchaAnswer,
  })
  console.log(registrationData)
  const result = await fetch(`${domain}users/addawaitingapproval`, {
    method: 'post',
    body: registrationData,
  })
  return result.json()
}
