import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { REDUX_SLICE_STATUS_IDLE, REDUX_SLICE_STATUS_LOADING } from "../constantsConfig/constantsConfig"
import {
  changeCategory,
  deleteDocument,
  fetchDocuments,
  uploadDocument,
} from './DocumentsApi'

const initialState = {
  documentsList: [],
  uploadedDocuments: [],
  status: REDUX_SLICE_STATUS_IDLE,
  uploadInProgress: false,
  success: null,
  errors: [],
  deleteSuccess: false,
  showSearchActiveFor: '',
  categoryChangedSuccessfully: false,
  categoryChangedRequestSent: false,
  categoryList: [
    { label: 'Stadgar', value: 'stadgar' },
    { label: 'Dokument från senaste årsstämma', value: 'senastearsstamma' },
    { label: 'Dokument inför kommande årsstämma', value: 'kommandearsstamma' },
    {
      label: 'Instruktioner och rekommendationer',
      value: 'instruktionerrekommendationer',
    },
    { label: 'Övriga dokument', value: 'ovrigt' },
  ],
  isRejected: false
}

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDocumentsListAsync = createAsyncThunk(
  'documents/getAll',
  async () => {
    const response = await fetchDocuments()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const getUploadedDocumentsListAsync = createAsyncThunk(
  'documents/getAllUploaded',
  async () => {
    const response = await fetchDocuments()
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const uploadDocumentAsync = createAsyncThunk(
  'documents/upload',
  async (formData) => {
    const response = await uploadDocument(formData)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const deleteDocumentAsync = createAsyncThunk(
  'documents/delete',
  async (documentId) => {
    const response = await deleteDocument(documentId)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const changeCategoryAsync = createAsyncThunk(
  'documents/changeCategory',
  async ({ documentId, newCategory }) => {
    const response = await changeCategory(documentId, newCategory)
    // The value we return becomes the `fulfilled` action payload
    return response
  },
)

export const documentsSlice = createSlice({
  name: 'documents',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    resetSuccess(state) {
      state.success = null
    },
    resetDeleteSuccess(state) {
      state.deleteSuccess = null
    },
    resetDocumentsList(state) {
      state.documentsList = []
    },
    resetUploadedDocuments(state) {
      state.uploadedDocuments = []
    },
    setShowSearchActiveFor(state, action) {
      state.showSearchActiveFor =
        state.showSearchActiveFor == '' ? action.payload : ''
    },
    resetCategoryChanged(state) {
      state.categoryChangedRequestSent = false
      state.categoryChangedSuccessfully = false
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDocumentsListAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getDocumentsListAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getDocumentsListAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.documentsList = action.payload
      })
      .addCase(uploadDocumentAsync.pending, (state, action) => {
        state.uploadInProgress = true
        state.isRejected = false
      })
      .addCase(uploadDocumentAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(uploadDocumentAsync.fulfilled, (state, action) => {
        state.success = action.payload.errors.length === 0
        state.uploadInProgress = false
        if (!state.success) {
          state.errors = action.payload.errors
        }
      })
      .addCase(getUploadedDocumentsListAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(getUploadedDocumentsListAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(getUploadedDocumentsListAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.uploadedDocuments = action.payload
      })
      .addCase(deleteDocumentAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.isRejected = false
      })
      .addCase(deleteDocumentAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(deleteDocumentAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.deleteSuccess = action.payload > -1
      })
      .addCase(changeCategoryAsync.pending, (state, action) => {
        state.status = REDUX_SLICE_STATUS_LOADING
        state.categoryChangedRequestSent = true
        state.isRejected = false
      })
      .addCase(changeCategoryAsync.rejected, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.isRejected = true
      })
      .addCase(changeCategoryAsync.fulfilled, (state, action) => {
        state.status = REDUX_SLICE_STATUS_IDLE
        state.categoryChangedSuccessfully = action.payload === 1
      })
  },
})

export const {
  resetSuccess,
  resetDeleteSuccess,
  resetDocumentsList,
  resetUploadedDocuments,
  setShowSearchActiveFor,
  resetCategoryChanged,
} = documentsSlice.actions
export const getDocumentsList = (state) => state.documents.documentsList
export const getUploadedDocuments = (state) => state.documents.uploadedDocuments
export const getUploadProgress = (state) => state.documents.uploadInProgress
export const getSuccess = (state) => state.documents.success
export const getErrors = (state) => state.documents.errors
export const getDeleteSuccess = (state) => state.documents.deleteSuccess
export const getIsLoading = (state) => state.documents.status != REDUX_SLICE_STATUS_IDLE
export const getShowSearchActiveFor = (state) =>
  state.documents.showSearchActiveFor
export const getCategoryChangedRequestSent = (state) =>
  state.documents.categoryChangedRequestSent
export const getCategoryChangedSuccessfully = (state) =>
  state.documents.categoryChangedSuccessfully
export const getCategoryList = (state) => state.documents.categoryList

export default documentsSlice.reducer
